import React, { useState } from 'react'
import styled, { StyledProps } from 'styled-components'

import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import WarningIcon from '@material-ui/icons/Warning'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SnackbarContent from '@material-ui/core/SnackbarContent'

type ToastType = 'info' | 'error' | 'success'

interface Props {
  message: string
  duration?: number
  variant: ToastType
}

interface ContentProps {
  toastType: ToastType
}

const DEFAULT_AUTOHIDE_DURATION = 3000

export const TOAST_VARIANT_INFO = 'info'
export const TOAST_VARIANT_ERROR = 'error'
export const TOAST_VARIANT_SUCCESS = 'success'

const ToastBar = styled(Snackbar)`
  padding: 8px;
  width: 1280px;
  max-width: 95%;
`

// Filter out custom props so they aren't passed into the DOM
const ToastContent = styled(({ toastType, ...props }) => (
  <SnackbarContent {...props} />
))(
  ({ theme, toastType }: StyledProps<ContentProps>) => `  
    width: 100%;
    min-height: 60;
    padding: ${theme.spacing(1, 4)};
    background-color: ${theme.palette.toast[toastType]};
`
)

// Filter out custom props so they aren't passed into the DOM
const StyledButton = styled(({ toastType, ...props }) => <Button {...props} />)(
  ({ theme, toastType }: StyledProps<ContentProps>) => `
  background-color: ${theme.palette.toast[toastType]};
`
)

const StyledTypography = styled(Typography)`
  margin-left: 8px;
`

const CloseButton = styled(CloseIcon)`
  font-size: 20px;
`

const MessageContainer = styled.span`
  display: flex;
  align-items: center;
`

const Toast: React.FC<Props> = ({ message, variant, duration }) => {
  const [isOpen, setisOpen] = useState(false)

  const openToast = () => setisOpen(true)

  const closeToast = () => setisOpen(false)

  const getIcon = (variant: ToastType) => {
    switch (variant) {
      case TOAST_VARIANT_ERROR:
        return <WarningIcon />
      case TOAST_VARIANT_SUCCESS:
        return <CheckCircleIcon />
      default:
        return <InfoIcon />
    }
  }

  return (
    <>
      <StyledButton variant="contained" toastType={variant} onClick={openToast}>
        {variant} Toast
      </StyledButton>
      <ToastBar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={closeToast}
        autoHideDuration={duration || DEFAULT_AUTOHIDE_DURATION}
      >
        <ToastContent
          toastType={variant}
          message={
            <MessageContainer>
              {getIcon(variant)}
              <StyledTypography variant="body1">{message}</StyledTypography>
            </MessageContainer>
          }
          action={
            <IconButton
              key="close"
              color="inherit"
              aria-label="Close"
              onClick={closeToast}
            >
              <CloseButton />
            </IconButton>
          }
        />
      </ToastBar>
    </>
  )
}

export default React.memo(Toast)
