import React from 'react'
import styled from 'styled-components'

const FooterWrapper = styled.footer(
    ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(4)}px;
  `
)

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
        Footer
    </FooterWrapper>
  )
}

export default Footer
