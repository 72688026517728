import React from 'react'
import styled from 'styled-components'

import Footer from 'src/modules/_core/Footer'
import Catalogue from 'src/modules/catalogue'
import Navigation from 'src/modules/_core/Navigation'
import SkipLink from 'src/components/navigation/SkipLink'

const Main = styled.main(
  ({ theme }) => `
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: ${theme.spacing(0, 4)};
`
)

const App: React.FC = () => {
  return (
    <>
      <SkipLink href="#main-pass">Skip to main content</SkipLink>
      <Navigation />
      <Main id="main-pass">
        <Catalogue />
      </Main>
      <Footer />
    </>
  )
}

export default App
