import React, { useRef, useState, useEffect } from 'react'
import styled, { StyledProps } from 'styled-components'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import Favorite from '@material-ui/icons/Favorite'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Toast, {
  TOAST_VARIANT_INFO,
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
} from 'src/components/feedback/Toast'
import LoaderButton from 'src/components/inputs/LoaderButton'
import CatalogueForm from 'src/modules/catalogue/CatalogueForm'

interface ColorBoxProps {
  shade: 'main' | 'light' | 'dark'
  type: 'error' | 'success' | 'warning' | 'primary' | 'secondary'
}

interface RowProps {
  maxWidth?: boolean
}

const Title = styled(Typography)(
  ({ theme }) => `
  margin: ${theme.spacing(0, 0, 2, 0)}
`
)

const Row = styled.div(
  ({ theme, maxWidth }: StyledProps<RowProps>) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(3)}px;
    max-width: ${maxWidth ? '275px' : 'none'};
  `
)

const ColorBox = styled.div(
  ({ theme, type, shade }: StyledProps<ColorBoxProps>) => `
      width: 85px;
      height: 85px;
      background-color: ${theme.palette[type][shade]};
  `
)

const ButtonContainer = styled.div`
  width: 150px;
`

const SelectControl = styled(FormControl)`
  width: 125px;
`

const menuValues = ['David', 'Tobias', 'Clayton']

const Catalogue: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [selected, setSelected] = useState(menuValues[0])
  const [labelWidth, setLabelWidth] = useState(0)

  const inputLabel = useRef<HTMLLabelElement>(null)
  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  const exampleLoad = () => {
    setLoading(true)
    setTimeout(() => setLoading(false), 2000)
  }

  const toggleChecked = () => setChecked(!checked)

  const selectValue = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string)
  }

  return (
    <Grid container spacing={2}>
      {/* 
        
        Colors
        
        */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Colours</Typography>
        <Divider />
        <Box m={4} />
        <Title variant="h5">Primary Colours</Title>
        <Row maxWidth>
          <ColorBox type="primary" shade="light" />
          <ColorBox type="primary" shade="main" />
          <ColorBox type="primary" shade="dark" />
        </Row>
        <Title variant="h5">Secondary Colours</Title>
        <Row maxWidth>
          <ColorBox type="secondary" shade="light" />
          <ColorBox type="secondary" shade="main" />
          <ColorBox type="secondary" shade="dark" />
        </Row>
        <Title variant="h5">Status Colours</Title>
        <Row maxWidth>
          <ColorBox type="error" shade="main" />
          <ColorBox type="success" shade="main" />
          <ColorBox type="warning" shade="main" />
        </Row>
      </Grid>
      {/* 
      
      Buttons
      
      */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Buttons</Typography>
        <Divider />
        <Box m={4} />
        <Title variant="h5">Contained Buttons</Title>
        <Row>
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
        </Row>
        <Title variant="h5">Outlined Buttons</Title>
        <Row>
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
          <Button variant="outlined" disabled>
            Disabled
          </Button>
        </Row>
        <Title variant="h5">Misc. Buttons</Title>
        <Row>
          <ButtonContainer>
            <LoaderButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              onClick={exampleLoad}
            >
              Loader Button
            </LoaderButton>
          </ButtonContainer>
          <IconButton color="primary" title="Delete">
            <DeleteIcon />
          </IconButton>
          <Button
            color="primary"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Row>
      </Grid>
      {/* 
      
      Typography
      
      */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Typography</Typography>
        <Divider />
        <Box m={4} />
        <Typography variant="h1" gutterBottom>Header 1</Typography>
        <Typography variant="h2" gutterBottom>Header 2</Typography>
        <Typography variant="h3" gutterBottom>Header 3</Typography>
        <Typography variant="h4" gutterBottom>Header 4</Typography>
        <Typography variant="h5" gutterBottom>Header 5</Typography>
        <Typography variant="h6" gutterBottom>Header 6</Typography>
        <Typography variant="subtitle2" gutterBottom>Subtitle 2</Typography>
        <Typography variant="subtitle1" gutterBottom>Subtitle 1</Typography>
        <Typography variant="body1" gutterBottom>Body 2</Typography>
        <Typography variant="body2" gutterBottom>Body 1</Typography>
        <Typography variant="caption" gutterBottom>Caption</Typography>
      </Grid>
      {/* 
      
      Selection Control
      
      */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Selection Control</Typography>
        <Divider />
        <Box m={4} />
        <Title variant="h5">Checkboxes</Title>
        <Row>
          <Row>
            <Checkbox color="primary" checked={true} />
            <Checkbox color="primary" disabled />
            <Checkbox color="primary" />
          </Row>
          <Row>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Primary"
            />
            <FormControlLabel
              control={<Checkbox disabled color="primary" />}
              label="Disabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  color="primary"
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                />
              }
              label="Custom Icon"
            />
          </Row>
        </Row>
        <Title variant="h5">Radio Buttons</Title>
        <Row>
          <Row>
            <Radio color="primary" disabled />
            <Radio color="primary" checked={checked} onClick={toggleChecked} />
          </Row>
          <Row>
            <FormControlLabel
              label="Disabled"
              control={<Radio disabled color="primary" />}
            />
            <FormControlLabel
              label="Primary"
              checked={checked}
              onClick={toggleChecked}
              control={<Radio color="primary" />}
            />
          </Row>
        </Row>
        <Title variant="h5">Selections</Title>
        <Row>
          <SelectControl variant="standard">
            <InputLabel id="demo-standard-label">Standard</InputLabel>
            <Select
              fullWidth
              value={selected}
              onChange={selectValue}
              labelId="demo-standard-label"
            >
              {menuValues.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </SelectControl>
          <SelectControl variant="filled">
            <InputLabel id="demo-filled-label">Filled</InputLabel>
            <Select
              fullWidth
              value={selected}
              onChange={selectValue}
              labelId="demo-filled-label"
            >
              {menuValues.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </SelectControl>
          <SelectControl variant="outlined">
            <InputLabel id="demo-outlined-label" ref={inputLabel}>
              Outlined
            </InputLabel>
            <Select
              fullWidth
              value={selected}
              onChange={selectValue}
              labelWidth={labelWidth}
              labelId="demo-outlined-label"
            >
              {menuValues.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </SelectControl>
        </Row>
      </Grid>
      {/* 
      
      Toast
      
      */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Toast</Typography>
        <Divider />
        <Box m={4} />

        <Row>
          <Toast
            message="This is an info toast!"
            variant={TOAST_VARIANT_INFO}
          />
          <Toast
            message="This is an error toast!"
            variant={TOAST_VARIANT_ERROR}
          />
          <Toast
            message="This is a success toast!"
            variant={TOAST_VARIANT_SUCCESS}
          />
        </Row>
      </Grid>
      {/* 
      
      Form
      
      */}
      <Grid item md={6} xs={12}>
        <Typography variant="h2">Basic Form example</Typography>
        <Divider />
        <Box m={4} />
        <CatalogueForm />
      </Grid>
    </Grid>
  )
}

export default Catalogue
