import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, FormikHelpers } from 'formik'

import Grid from '@material-ui/core/Grid'

import { loginValidation } from 'src/utils/validation'
import LoaderButton from 'src/components/inputs/LoaderButton'
import FormCheckbox from 'src/components/inputs/FormCheckbox'
import FormTextField from 'src/components/inputs/FormTextField'

interface FormValues {
  email: string
  password: string
  rememberMe: boolean
}

const StyledForm = styled(Form)(
  ({ theme }) => `
  margin: auto;
  max-width: 400px;
  border-radius: 4px;
  padding: ${theme.spacing(2)}px;
  box-shadow: 0px 5px 18px -2px rgba(0, 0, 0, 0.2);
`
)

const CheckboxContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`

const StyledLoader = styled(LoaderButton)(
  ({ theme }) => `margin: ${theme.spacing(2, 0, 0, 0)}`
)

const CatalogueForm: React.FC = () => {
  const [submitting, setSubmitting] = useState(false)

  const formikOptions = {
    initialValues: { email: '', password: '', rememberMe: false },
    validationSchema: loginValidation,
    onSubmit: (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      setSubmitting(true)
      setTimeout(() => {
        console.log(values)
        setSubmitting(false)
        resetForm()
      }, 2000)
    },
  }

  return (
    <Formik {...formikOptions}>
      <StyledForm>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormTextField name="email" label="Email" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              type="password"
              name="password"
              label="Password"
              fullWidth
            />
          </Grid>
          <CheckboxContainer item xs={12}>
            <FormCheckbox
              name="rememberMe"
              label="Remember Me"
              checkboxOptions={{ color: 'primary' }}
            />
          </CheckboxContainer>
        </Grid>
        <Grid item xs={12}>
          <StyledLoader
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            loading={submitting}
          >
            Login
          </StyledLoader>
        </Grid>
      </StyledForm>
    </Formik>
  )
}

export default CatalogueForm
