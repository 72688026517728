import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'

import App from 'src/modules/App'
import theme from 'src/styles/theme'
import * as serviceWorker from './serviceWorker'
import GlobalStyles from 'src/styles/GlobalStyles'

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <>
          <GlobalStyles />
          <CssBaseline />
          <App />
        </>
      </StylesProvider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
