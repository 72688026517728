import React from 'react'
import { useField } from 'formik'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'

interface Props {
  name: string
  label: string
  checkboxOptions?: CheckboxProps
}

const FormCheckbox: React.FC<Props> = ({ name, label, checkboxOptions }) => {
  const [field] = useField({ name, type: 'checkbox' })

  return (
    <FormControlLabel
      control={<Checkbox {...field} {...checkboxOptions} />}
      label={label}
    />
  )
}

export default React.memo(FormCheckbox)
