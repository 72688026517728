import React from 'react'
import { useField } from 'formik'
import styled, { StyledProps } from 'styled-components'

import TextField, { TextFieldProps } from '@material-ui/core/TextField'

interface RequiredProps {
  name: string
}

type Props = TextFieldProps & RequiredProps

interface ErrorProps {
  active: boolean
}

const StyledContainer = styled.div`
  position: relative;
`

const ErrorMessage = styled.span(
  ({ theme, active }: StyledProps<ErrorProps>) => `
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${active ? -20 : -5}px;
    color: ${theme.palette.error.main};
  `
)

const FormTextField: React.FC<Props> = ({ name, ...props }) => {
  const [field, meta] = useField(name)
  const hasError = meta.touched && !!meta.error

  return (
    <StyledContainer>
      <TextField error={hasError} {...field} {...props} />
      <ErrorMessage active={hasError}>{meta.error}</ErrorMessage>
    </StyledContainer>
  )
}

export default React.memo(FormTextField)
